// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-campaign-jsx": () => import("./../../../src/components/campaign.jsx" /* webpackChunkName: "component---src-components-campaign-jsx" */),
  "component---src-components-screen-jsx": () => import("./../../../src/components/screen.jsx" /* webpackChunkName: "component---src-components-screen-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-promo-jsx": () => import("./../../../src/templates/promo.jsx" /* webpackChunkName: "component---src-templates-promo-jsx" */)
}

